import {
  Doc,
  FeatureOrChain,
  Layout,
  Section,
  CurationIndicesMap
} from "../types";

import { colsToNumMap, tableKeys } from "./fusion-helpers";

const tableAndColumnKeys = tableKeys.filter(
  (key) => !["unassigned"].includes(key)
);

const getTableAndColumnIndices = (
  id: string,
  customFields: any
): CurationIndicesMap => {
  const obj: CurationIndicesMap = {};

  if (!id || !customFields) return obj;

  if (!/^renderable-features-/.test(id)) id = `renderable-features-${id}`;

  tableAndColumnKeys.some((key) => {
    const tableRegex = /table(\d+)(Left|Main|Right|Bottom)/;
    if (
      (customFields?.[`${key}_uniqueIds`] || []).includes(id) &&
      tableRegex.test(key)
    ) {
      const match = tableRegex.exec(key)!;
      obj.table = Number.parseInt(match[1]);
      obj.column = colsToNumMap[match[2]] || 0;
      return true;
    }
    return false;
  });
  return obj;
};

const calcCurationRank = ({
  zone = 0,
  chainorfeature = 0,
  table = 0,
  column = 0,
  feature = 0
}) =>
  zone * 10 ** 12 +
  chainorfeature * 10 ** 9 +
  table * 10 ** 6 +
  column * 10 ** 3 +
  feature * 10 ** 0;

const addCurationRank = (indices: any) => ({
  ...indices,
  rank: calcCurationRank(indices)
});

// NOTE: the admin and render endpoints uses this
function addCurationIndicesFromSnapshot(currentDoc: Doc): void {
  let chainIndex = -1;
  currentDoc?.root?.children?.forEach(({ id: zoneId }, zoneIndex: number) => {
    currentDoc?.[zoneId]?.children?.forEach(
      ({ id: chainorfeatureId }, chainorfeatureIndex: number) => {
        const chainorfeature = currentDoc?.[chainorfeatureId];
        chainIndex += /chains/.test(chainorfeature?.collection) ? 1 : 0;
        if (chainorfeature?.props) {
          chainorfeature.props.curationIndices = addCurationRank({
            zone: zoneIndex,
            chainorfeature: chainorfeatureIndex,
            ...(/chains/.test(chainorfeature?.collection)
              ? { chain: chainIndex }
              : {}),
            ...(/features/.test(chainorfeature?.collection)
              ? { table: 0, column: 0, feature: 0 }
              : {})
          });
        }
        currentDoc?.[chainorfeatureId]?.children?.forEach(
          ({ id: featureId }, featureIndex: number) => {
            const feature = currentDoc?.[featureId];
            if (feature?.props) {
              feature.props.curationIndices = addCurationRank({
                zone: zoneIndex,
                chainorfeature: chainorfeatureIndex,
                ...(/chains/.test(chainorfeature?.collection)
                  ? { chain: chainIndex }
                  : {}),
                ...getTableAndColumnIndices(
                  feature.props?.id,
                  chainorfeature?.props?.customFields
                ),
                feature: featureIndex
              });
            }
          }
        );
      }
    );
  });
}

// NOTE: the jsonapp endpoint uses this
const addCurationIndicesFromRendering = (currentDoc: Layout): void => {
  let chainIndex = -1;
  currentDoc?.children?.forEach((zone: Section, zoneIndex: number) => {
    zone?.children?.forEach(
      (chainorfeature: FeatureOrChain, chainorfeatureIndex: number) => {
        chainIndex += /chains/.test(chainorfeature?.collection) ? 1 : 0;
        if (chainorfeature?.props) {
          chainorfeature.props.curationIndices = addCurationRank({
            zone: zoneIndex,
            chainorfeature: chainorfeatureIndex,
            ...(/chains/.test(chainorfeature?.collection)
              ? { chain: chainIndex }
              : {}),
            ...(/features/.test(chainorfeature?.collection)
              ? { table: 0, column: 0, feature: 0 }
              : {})
          });
        }
        chainorfeature?.children?.forEach((feature, featureIndex) => {
          if (feature?.props) {
            feature.props.curationIndices = addCurationRank({
              zone: zoneIndex,
              chainorfeature: chainorfeatureIndex,
              ...(/chains/.test(chainorfeature?.collection)
                ? { chain: chainIndex }
                : {}),
              ...getTableAndColumnIndices(
                feature.props?.id,
                chainorfeature?.props?.customFields
              ),
              feature: featureIndex
            });
          }
        });
      }
    );
  });
};

export const addCurationIndices = (currentDoc: Doc | Layout): void => {
  if (currentDoc) {
    if ("root" in currentDoc && currentDoc?.root) {
      addCurationIndicesFromSnapshot(currentDoc);
    }
    if (currentDoc?.children) {
      // @ts-ignore
      addCurationIndicesFromRendering(currentDoc);
    }
  }
};
