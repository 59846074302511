export const HPBreakpoints = {
  mx: 1400,
  lg: 1150,
  mdLg: 1024,
  md: 900,
  sm: 768,
  xs: 425
};

export const gridPixValues = {
  columnWidth: 34,
  gutterWidth: 32
};

const dividerInfo = {
  includeDividers: true
};

// NOTE: If useDesktopOrdering changes at any of the breakpoints below then
// order must change correspondingly in:
//    src/websites/washpost/fronts/css-grid.scss
// If useDesktopOrdering varies per table/column, then this will not be sufficient
export const IMPLICIT_GRID_INFO = [
  {
    bp: "mx",
    bpVerbose: "xlarge",
    fullColSpan: 20,
    ...dividerInfo,
    useDesktopOrdering: true
  },
  {
    bp: "lg",
    bpVerbose: "large",
    fullColSpan: 16,
    ...dividerInfo,
    useDesktopOrdering: true
  },
  {
    bp: "md",
    bpVerbose: "medium",
    fullColSpan: 12,
    ...dividerInfo,
    useDesktopOrdering: true
  },
  {
    bp: "sm",
    bpVerbose: "small",
    fullColSpan: 10,
    ...dividerInfo,
    useDesktopOrdering: false
  },
  {
    bp: "xs",
    bpVerbose: "xsmall",
    fullColSpan: 1,
    ...dividerInfo,
    useDesktopOrdering: false
  }
];

export const VERBOSE_TO_CONCISE_BP_MAP = IMPLICIT_GRID_INFO.reduce(
  (acc, info) => {
    acc[info.bpVerbose] = info.bp;
    return acc;
  },
  {}
);

export const bps = IMPLICIT_GRID_INFO.map(({ bp }) => bp);

export const bpsVerbose = IMPLICIT_GRID_INFO.map(({ bpVerbose }) => bpVerbose);

export const GRID_PLACEHOLDER_INFO = {
  styleKey: "none",
  styles: {},
  value: 0
};

export const createImplicitGridStyles = () =>
  IMPLICIT_GRID_INFO.reduce((acc, info) => {
    acc[`--c-span-${info.bp}`] = info.fullColSpan;
    return acc;
  }, {});

const createFullColSpanGridStyles = () =>
  IMPLICIT_GRID_INFO.reduce(
    (acc, info) => {
      acc[`--c-start-${info.bp}`] = 1;
      return acc;
    },
    { ...createImplicitGridStyles() }
  );

export const fullColSpanGridStyles = createFullColSpanGridStyles();

export const createRowStyles = (
  rowSpan,
  layoutVars = {},
  parentLayoutVars = {}
) =>
  IMPLICIT_GRID_INFO.reduce((acc, info) => {
    // NOTE: Not specifying row span 1 in some cases causes problems, so do it.
    if (rowSpan > 0) {
      const bp = info.bp;
      const fullColSpan = info.fullColSpan;
      const parentColSpan = parentLayoutVars[`--c-span-${bp}`];
      const parentRowSpan = parentLayoutVars[`--r-span-${bp}`];

      // NOTE: If col span of item is the full width of either the table
      // or the grid it's in, then rowSpan should be 1. The default will be
      // the parent rowSpan. So, if parent rowSpan is 1, do nothing.
      // Otherwise set row span to 1.
      if (
        (parentColSpan && layoutVars[`--c-span-${bp}`] === parentColSpan) ||
        (fullColSpan && layoutVars[`--c-span-${bp}`] === fullColSpan)
      ) {
        if (parentRowSpan && parentRowSpan > 1) {
          acc[`--r-span-${bp}`] = 1;
        }
        return acc;
      }

      // NOTE: If it's not tiling, obey the rowSpan meaning tiling regime
      // will always span 1 row -- seems ok but change if it's not!
      if (layoutVars[`--c-start-${bp}`] !== 0) {
        acc[`--r-span-${bp}`] = rowSpan;
      }
    }
    return acc;
  }, {});

export const getColsFromWidth = (w) =>
  Math.ceil(
    (w - gridPixValues.columnWidth) /
      (gridPixValues.columnWidth + gridPixValues.gutterWidth) +
      1
  );

export const getLayoutVarsFromWidth = (specs = {}) => {
  const cols = specs.all ? getColsFromWidth(specs.all) : undefined;
  return IMPLICIT_GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    // NOTE: xs, if present, always gets 1
    if (cols) {
      acc[`--c-span-${bp}`] = /xs/.test(bp) ? 1 : cols;
    } else if (specs[bp]) {
      acc[`--c-span-${bp}`] = /xs/.test(bp) ? 1 : getColsFromWidth(specs[bp]);
    }
    return acc;
  }, {});
};
