import React from "react";
import { AdminFeatureContext } from "../types";

const Unimplemented = () => null;
export const ComponentMapContext = React.createContext<{
  [key: string]: {
    Component: any;
    fields: any;
    name?: string;
    icon?: string;
    collection?: string;
    childFeatures?: string[];
    siblingFeatures?: string[];
    toolbarHelper?: {
      getToolbarInfo: any;
    };
    isGridItem?: boolean;
    presetOptions?: {
      presets?: [];
      mini?: [];
      mobile?: [];
    };
    presetHelpers?: {
      getLastPickedPreset?: Function;
      getLastPickedMiniPreset?: Function;
      getMobilePreset?: Function;
    };
    fieldComponents?: any;
  };
}>({});

export const useComponentFields = (type: string) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.fields || Unimplemented;
};

export const useAllComponentFields = () => {
  const components = React.useContext(ComponentMapContext);
  const fields: any = {};
  Object.keys(components).forEach(
    (comp: string) => (fields[comp] = components[comp].fields)
  );
  return fields;
};

export const useComponentMap = (type: string | undefined) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.Component || Unimplemented;
};

export const useComponentIcon = (type: string | undefined) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.icon || "";
};

export const useComponent = (type: string) => {
  const components = React.useContext(ComponentMapContext);
  return components[type || "unimplemented"] || { Component: Unimplemented };
};

export const useComponentChildFeatures = (type: string | undefined) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.childFeatures;
};

export const useComponentToolbarHelper = (type: string | undefined) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.toolbarHelper;
};

export const useComponentName = (type: string | undefined) => {
  const components = React.useContext(ComponentMapContext);
  const componentDef =
    components[type || "unimplemented"] || components.unimplemented;
  return componentDef?.name || "";
};

const defaultSelectors = {
  ChainColumnDropTarget: () => null,
  styles: {},
  OverlayBar: () => null,
  overlay: null,
  overlayLeft: null
};
const defaultInteractions = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {}
};

export const ChainContext = React.createContext<
  AdminFeatureContext & { showHelpers: boolean; showFeatureNames?: boolean }
>({
  ref: null,
  selectEntity: () => {},
  setFeaturesInChains: () => {},
  selectors: defaultSelectors,
  interactions: defaultInteractions,
  showHelpers: false,
  showFeatureNames: false,
  dataAttrs: {}
});
export const FeatureContext = React.createContext<
  AdminFeatureContext & { suppressWarning: boolean }
>({
  ref: null,
  suppressWarning: false,
  selectEntity: () => {},
  selectors: defaultSelectors,
  interactions: defaultInteractions,
  dataAttrs: {}
});

export const useSpartanFeature = () => React.useContext(FeatureContext);
export const useSpartanChain = () => React.useContext(ChainContext);
